import React, { useState, useEffect, lazy, Suspense } from "react";

const Cursor = lazy(() => import("./shared/components/UIElement/Cursor"));
const Scroll = lazy(() => import("./shared/components/UIElement/Scroll"));
const MainFooter = lazy(() => import("./shared/components/Navigation/MainFooter"));

const Feature = lazy(() => import("./feature/pages/Feature"));
const Intro = lazy(() => import("./intro/pages/Intro"));
const About = lazy(() => import("./about/pages/About"));
const TempContact = lazy(() => import("./showcase/pages/Showcase"));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  };

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector(".loader");
      if (el) {
        el.remove();
        setIsLoading(!isLoading);
      }
    });
  }, []);

  const renderLoader = () => {
    return (
      <div className="loader">
        <div className="square"></div>
        <div className="square"></div>
        <div className="square last"></div>
        <div className="square clear"></div>
        <div className="square"></div>
        <div className="square last"></div>
        <div className="square clear"></div>
        <div className="square"></div>
        <div className="square last"></div>
      </div>
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Cursor pageLoading={isLoading} />
        <Scroll>
          <Feature />
          <Intro />
          <About />
          <TempContact />
        </Scroll>
        {/* <Contact /> */}
        <MainFooter />
      </Suspense>
    </>
  );
}

export default App;
